import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/blogpost.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "https://media2.giphy.com/media/3ornk57KwDXf81rjWM/giphy.gif",
        "alt": "Hello there"
      }}></img></p>
    <p>{`This article is merely a placeholder to prime this website's GraphQL schema. Once there's a real article series this can go away, but until then you're stuck with this. On the next page, there's an excerpt from Joseph Conrad's "Heart of Darkness", which is a much more interesting body text than lorem ipsum.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      